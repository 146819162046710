// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Column,
  Banner,
  VariableContent,
  Image,
  List,
  ListItem,
  LeshenPhoneCTA,
  PackageCard,
  Brandy,
  Price,
  SplitContent,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Columns, Typography } from '@leshen/ui'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Frontier<sup>&reg;</sup> Fiber Internet
                </Typography>
                <Typography variant="h4">
                  AVAILABLE IN {State_Name?.toUpperCase()}
                </Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Amazon eero Wi-Fi Pro 6 router included<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Extreme bandwidth for smart homes with dozens of devices
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  Call
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Fiber Internet from Frontier: Reliable speeds for all your
                  needs
                </Typography>
                <Typography variant="">
                  All Frontier Fiber Internet plans include a FREE premium Wi-Fi
                  router, NO data caps, and internet speeds that can power all
                  your devices.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package3}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package4}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />
            </Columns>
          </VariableContent>
          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  Check availability by city
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                  Find the best Internet deals in your area:
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />

          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Cover all your bases with Frontier Fiber Internet
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                backgroundColor=""
                borderColor="none"
                borderType="none"
                image={false}
                svg={false}
              >
                <Typography variant="h4">Tech & Speed</Typography>
                <Typography variant="">
                  Nothing beats a fast connection with virtually no lag time.
                  With Frontier Fiber internet, you get 100% fiber-optic speeds
                  right at your fingertips. Power all devices in your home with
                  the speed of fiber and experience the internet like it was
                  meant to be.
                </Typography>
              </Column>
              <Column
                backgroundColor=""
                borderColor="none"
                borderType="none"
                image={false}
                svg={false}
              >
                <Typography variant="h4">Entertainment</Typography>
                <Typography variant="">
                  A surefire way to put a damper on a critical moment in your
                  show is a laggy connection. Make sure your entertainment is
                  backed by 100% fiber-optic technology. You can stream, game,
                  browse, and watch your favorite shows and movies anytime. Save
                  the drama for the show, not your connection.
                </Typography>
              </Column>
              <Column
                backgroundColor=""
                borderColor="none"
                borderType="none"
                image={false}
                svg={false}
              >
                <Typography variant="h4">Sports & Culture</Typography>
                <Typography variant="">
                  Got a podcast you love? How about a sports team you cheer for?
                  Frontier Fiber Internet gives you the bandwidth you need to
                  stream, listen, watch, and revel in your favorite online
                  activities without interruption. Whether it’s catching the
                  latest highlights from your sport of choice or streaming your
                  favorite channel live, Frontier has you covered.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.vrGuy.cloudinary[0].gatsbyImageData}
                alt={data.vrGuy.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.vrGuyMobile.cloudinary[0].gatsbyImageData}
                alt={data.vrGuyMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Fiber for serious internet users
                </Typography>
                <Typography variant="h4">
                  Own the internet like you never have with new top speeds as
                  fast as 5 Gig.
                </Typography>
                <Typography>
                  Stream 4K movies, get into VR gaming, and work from home on
                  dozens of devices at some of the fastest internet speeds on
                  the market.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Extreme bandwidth for smart homes with dozens of devices
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Extend your Wi-Fi signal starting at $10/mo<sup>♦</sup>
                </Typography>
                <Typography>
                  Eliminate dead spots by adding Whole-Home Wi-Fi extenders to
                  your plan.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <SplitContent
            mainContent={<></>}
            image={
              <Image
                data={data.homePhoneImage.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.homePhoneImageMobile.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
          >
            <Typography variant="h2">
              Save big with Frontier bundles in {State_Name}
            </Typography>
            <Typography variant="">
              Get all the home services you need with a bundled plan from
              Frontier. When you sign up for Frontier phone and internet in{' '}
              {State_Name}, you’ll get both services on one bill for one low
              price. Here are a few of the benefits you look forward to with a
              bundled package from Frontier:
            </Typography>
            <List>
              <ListItem>
                <Typography>Unlimited nationwide calling</Typography>
              </ListItem>
              <ListItem>
                <Typography>Unlimited monthly data</Typography>
              </ListItem>
              <ListItem>
                <Typography>Free 24/7 tech support</Typography>
              </ListItem>
            </List>
            <br />
            <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
          </SplitContent>
          <SplitContent
            image={
              <Image
                data={data.tvImage.cloudinary[0].gatsbyImageData}
                alt={data.tvImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.tvImageMobile.cloudinary[0].gatsbyImageData}
                alt={data.tvImageMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={<></>}
          >
            <Typography variant="h2">Better way to get live TV</Typography>
            <Typography variant="">
              Get your favorite channels with Frontier Fiber and YouTube TV. Get
              100+ channels of live sports, breaking news, and must-see shows
              and movies. Sign up today and save $15 a month for the next 12
              months.<sup>^</sup> With no installation required, you can sign up
              and start watching in minutes. When you add YouTube TV to your
              Fronter package, you will receive:
            </Typography>
            <List>
              <ListItem>
                <Typography>100+ live channels</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Free DVR recording with unlimited cloud storage.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>No long-term contracts</Typography>
              </ListItem>
            </List>
            <Brandy
              symbol=""
              text="Limited time offer for Frontier Internet subscribers who are first-time YouTube TV customers. Must activate 6/16-9/15. Terms apply.^"
              variant="legal"
              modal={false}
            />
          </SplitContent>
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFour.symbol}
                  text={data.disclaimerFour.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFive.symbol}
                  text={data.disclaimerFive.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSix.symbol}
                  text={data.disclaimerSix.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "9f079251-dcfd-5307-993f-a204c2991dab" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "2f67302f-2fd4-55d3-8805-d6330dd58187" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    footerDisclaimers: allContentfulTemplate(
      filter: { name: { eq: "Default" } }
    ) {
      edges {
        node {
          footerDisclaimers {
            brandy {
              text
              symbol
            }
          }
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "52DWwjVrmw65yz5dKDbdjk" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImage: contentfulMedia(
      contentful_id: { eq: "264aNICaC50Jw6uh87xOkJ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImageMobile: contentfulMedia(
      contentful_id: { eq: "3EWyu3ec3lOo5QMom3D1eq" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    tvImage: contentfulMedia(contentful_id: { eq: "6q2fF47qLY1Ib1u8YxvYZP" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    tvImageMobile: contentfulMedia(
      contentful_id: { eq: "1BlFwd2GXwWMciPnTCalTF" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuy: contentfulMedia(contentful_id: { eq: "74Sk7cjUnv5TzoGM9A9QL4" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuyMobile: contentfulMedia(
      contentful_id: { eq: "7uf13p4haNbePfmzS0lPOL" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-index-hero" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-5gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-2gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-1gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package4: brandyPackage(brandy_id: { eq: "ftr-sigil-500mbp" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-visa" }) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-eeroo" }) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-archer-router" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-fibermptp" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-fiber" }) {
      id
      text
      symbol
    }
  }
`
